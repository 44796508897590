import { Navbar } from './types';

const newDeviceTabs = {
  available: [
    { name: 'Overview', value: 'overview' },
    { name: 'Hard disk', value: 'hard_disk' },
    {
      name: 'Management',
      value: 'management',
      subtabs: ['rf_tuning', 'channels', 'speed_test', 'settings']
    },
    {
      name: 'Account',
      value: 'account',
      subtabs: ['provisioned_devices', 'subscriptions', 'dvr', 'vod']
    },
    { name: 'Activity log', value: 'activity_log' },
    {
      name: 'Monitoring',
      value: 'monitoring',
      subtabs: ['device_checks'],
    },
  ],
  current: { name: 'Overview', value: 'overview' },
  i18nkey: 'all_device_pages'
};

export function deviceTabs(showLogs: boolean) {
  if(showLogs && newDeviceTabs.available[2].subtabs) {
    newDeviceTabs.available[2].subtabs.push('logs')
  }

  return newDeviceTabs as Navbar
}

export const spectrumTabs: Navbar = {
  available: [
    { name: 'Homepage', value: 'homepage' },
    { name: 'DVB management', value: 'dvb_management' },
    {
      name: 'Logical channels',
      value: 'logical_channels',
      subtabs: ['channels', 'regionalization']
    },
    { name: 'Requests', value: 'requests' }
  ],
  current: { name: 'Homepage', value: 'homepage' },
  i18nkey: 'spectrum'
};

export const navbarWithTabs = [
  '/operational/device/',
  '/management/spectrum',
];
