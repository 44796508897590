import { connect } from "react-redux"
import LogsProfile from "./profile-component"
import { open as openModal, close as closeModal } from 'core/modal/redux/actions'
import { runCommands } from '../../redux/actions'

export default connect(() => ({}), {
  openModal,
  closeModal,
  runCommands,
})(LogsProfile)
